import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { addNotification } from "../App";
import { handleError } from "../libs/errors";
import dayjs from "dayjs";
import KegiatanSekolah from "../components/CommBook/KegiatanSekolah";
import KegiatanRumah from "../components/CommBook/KegiatanRumah";
import KegiatanSekolahAshlein from "../components/CommBook/KegiatanSekolahAshlein";
import KegiatanRumahAshlein from "../components/CommBook/KegiatanRumahAshlein";
import KegiatanRumahNew from "../components/CommBook/KegiatanRumahNew";
import KegiatanRumahLittleCamel from "../components/CommBook/KegiatanRumahLittleCamel";
import KegiatanSekolahLittleCamel from "../components/CommBook/KegiatanSekolahLittleCamel";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class CommBook extends Component {
  state = {
    filterModalVisible: false,
    category: "ORTU",
    date: dayjs().format("YYYY-MM-DD"),

    filteredDate: dayjs().format("YYYY-MM-DD"),
    filterCalendarVisible: false,
    academicYear: defaultAcademicYear(),
    bukuPenghubungCustomType: "",
  };

  componentDidMount = () => {
    this.componentWillReceiveProps(this.props);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allStudents && nextProps.allStudents.length > 0) {
      urlValues = queryString.parse(nextProps.location.search);

      let studentId = "";
      let PREFIX = "";
      if (!urlValues.studentId) {
        studentId = nextProps.allStudents[0]._id;
        PREFIX = nextProps.allStudents[0].PREFIX;
        this.props.history.replace({
          pathname: "/comm_book",
          search: `?sessionId=${urlValues.sessionId}&studentId=${studentId}&PREFIX=${PREFIX}`,
        });
      } else {
        studentId = urlValues.studentId;
        PREFIX = urlValues.PREFIX;
      }
    }
  };
  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    this.setState({
      bukuPenghubungCustomType: "",
    });
    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/comm_book",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  showAspect = (category) => (e) => {
    this.setState({
      category,
    });
  };

  closeCalendar = () => {
    this.setState({
      filterCalendarVisible: false,
      date: dayjs().format("YYYY-MM-DD"),
    });
  };

  submitFilterDate = (e) => {
    if (e) e.preventDefault();

    const month = dayjs(this.state.date).get("month") + 1;

    let academicYear = "";
    if (month > 6) {
      academicYear = `${dayjs(this.state.date).get("year")}/${
        dayjs(this.state.date).get("year") + 1
      }`;
    } else {
      academicYear = `${dayjs(this.state.date).get("year") - 1}/${dayjs(
        this.state.date
      ).get("year")}`;
    }
    this.setState({
      filteredDate: this.state.date,
      filterCalendarVisible: false,
      academicYear,
    });
  };

  handleChangeDate = (e) => {
    this.setState({
      date: e.target.value,
    });
  };

  openCalendar = (e) => {
    if (e) e.preventDefault();
    this.setState({
      filterCalendarVisible: true,
    });
  };

  render() {
    const {
      category,
      filteredDate,
      filterCalendarVisible,
      date,
      academicYear,
      bukuPenghubungCustomType,
    } = this.state;
    const { allStudents } = this.props;
    // console.log(allStudents);

    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    console.log({ bukuPenghubungCustomType });
    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-calendar" /> Filter Tanggal
            </span>
          }
          visible={filterCalendarVisible}
          size="md"
          onClose={this.closeCalendar}
          onSubmit={this.submitFilterDate}
        >
          <div className="form-group">
            <label>Pilih Tanggal</label>
            <input
              className="form-control"
              type="date"
              value={date}
              onChange={this.handleChangeDate}
            />
          </div>
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-book"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"Buku Penghubung"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {getStudent &&
              getStudent.length !== 0 &&
              getStudent[0].PREFIX === "ALASHLEIN" ? (
                <div className="form-group">
                  <label>Jenis Buku Penghubung</label>
                  <select
                    className="form-control"
                    value={bukuPenghubungCustomType}
                    onChange={(e) => {
                      if (e) e.preventDefault();
                      this.setState({
                        bukuPenghubungCustomType: e.target.value,
                      });
                    }}
                  >
                    <option value={""}>REGULER</option>
                    <option value={"HALAQAH"}>HALAQAH</option>
                  </select>
                </div>
              ) : null}
            </div>
          </section>
        </div>

        <BackgroundImage />
        <View
          style={{
            marginTop: 280,
            marginBottom: "50vh",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-sm"
              style={{
                backgroundColor: category === "ORTU" ? "#00a8ff" : "#CFD8DC",
                color: category === "ORTU" ? "#fff" : "",
                marginRight: 10,
                borderRadius: "15px",
              }}
              onClick={this.showAspect("ORTU")}
            >
              Kegiatan Rumah
            </button>
            <button
              className="btn btn-sm"
              style={{
                backgroundColor: category === "SEKOLAH" ? "#00a8ff" : "#CFD8DC",
                color: category === "SEKOLAH" ? "#fff" : "",
                marginRight: 10,
                borderRadius: "15px",
              }}
              onClick={this.showAspect("SEKOLAH")}
            >
              Kegiatan Sekolah
            </button>
            <button
              className="btn btn-sm"
              style={{
                backgroundColor: "#00a8ff",
              }}
              onClick={this.openCalendar}
            >
              <Text
                style={{
                  color: "#fff",
                }}
              >
                <i className="fa fa-calendar" />
              </Text>
            </button>
          </View>

          <View>
            {category === "ORTU" ? (
              urlValues.PREFIX &&
              (urlValues.PREFIX === "ALASHLEIN" ||
                urlValues.PREFIX === "DEMOX") ? (
                <KegiatanRumahAshlein
                  academicYear={academicYear}
                  studentId={urlValues.studentId ? urlValues.studentId : ""}
                  date={filteredDate}
                  category={category}
                  bukuPenghubungCustomType={bukuPenghubungCustomType}
                />
              ) : urlValues.PREFIX === "DEMO" ? (
                <KegiatanRumahNew
                  academicYear={academicYear}
                  studentId={urlValues.studentId ? urlValues.studentId : ""}
                  date={filteredDate}
                  category={category}
                />
              ) : urlValues.PREFIX === "LITTLECAMEL" ? (
                <KegiatanRumahLittleCamel
                  academicYear={academicYear}
                  studentId={urlValues.studentId ? urlValues.studentId : ""}
                  date={filteredDate}
                  category={category}
                />
              ) : (
                <KegiatanRumah
                  academicYear={academicYear}
                  studentId={urlValues.studentId ? urlValues.studentId : ""}
                  date={filteredDate}
                  category={category}
                />
              )
            ) : urlValues.PREFIX &&
              (urlValues.PREFIX === "ALASHLEIN" ||
                urlValues.PREFIX === "DEMO") ? (
              <KegiatanSekolahAshlein
                academicYear={academicYear}
                studentId={urlValues.studentId ? urlValues.studentId : ""}
                date={filteredDate}
                category={category}
                bukuPenghubungCustomType={bukuPenghubungCustomType}
              />
            ) : urlValues.PREFIX === "LITTLECAMEL" ? (
              <KegiatanSekolahLittleCamel
                academicYear={academicYear}
                studentId={urlValues.studentId ? urlValues.studentId : ""}
                date={filteredDate}
                category={category}
                bukuPenghubungCustomType={bukuPenghubungCustomType}
              />
            ) : (
              <KegiatanSekolah
                academicYear={academicYear}
                studentId={urlValues.studentId ? urlValues.studentId : ""}
                date={filteredDate}
                category={category}
              />
            )}
          </View>

          <View></View>
        </View>

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa / Kelas Lain"}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}
const LIST_QUERIES = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      PREFIX
      photoUrl
    }
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  // console.log(urlValues);
  return (
    <ApolloConsumer>
      {(client) => (
        <Query
          query={LIST_QUERIES}
          client={mwvClient}
          variables={{
            _id: urlValues.sessionId,
          }}
        >
          {({ error, loading, data, refetch }) => (
            <div>
              <CommBook
                {...props}
                error={error}
                loading={loading}
                refetch={refetch}
                allStudents={
                  data && data.allStudentBySessionId
                    ? data.allStudentBySessionId
                    : []
                }
              />
            </div>
          )}
        </Query>
      )}
    </ApolloConsumer>
  );
});

const defaultAcademicYear = () => {
  let academicYear;
  if (new Date().getMonth() < 6) {
    academicYear = `${
      new Date().getFullYear() - 1
    }/${new Date().getFullYear()}`;
  } else {
    academicYear = `${new Date().getFullYear()}/${
      new Date().getFullYear() + 1
    }`;
  }
  return academicYear;
};
