import React from "react";
import moment from "moment";
// import { range } from "moment-range";
// import "./calendar.css";
import momentId from "moment/locale/id";
moment.locale("id", momentId);
// moment.locale("id");
export default class Calendar extends React.Component {
  weekdayshort = moment.weekdaysShort();

  state = {
    showCalendarTable: true,
    showMonthTable: false,
    dateObject: moment(),
    allmonths: moment.months(),
    showYearNav: false,
    selectedDay: null,
    showStatusPresensi: false,
  };
  componentDidMount() {
    const date = this.props.date
      ? this.props.date < 10
        ? 0 + this.props.date
        : this.props.date
      : null;
    const month =
      this.props.month < 10 ? 0 + this.props.month : this.props.month;
    const year = this.props.year;
    if (
      date !== null &&
      date !== "" &&
      date !== undefined &&
      parseInt(date) !== 0
    ) {
      this.setState({
        showStatusPresensi: true,
        dateObject: moment(`${year}-${month}-${date}`),
        selectedDay: date,
      });
    } else {
      this.setState({
        dateObject: moment(),
        selectedDay: moment().date(),
      });
    }
  }
  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };
  year = () => {
    return this.state.dateObject.format("Y");
  };
  yearAgo = () => {
    return this.state.dateObject.format("Y") - 7;
  };
  currentDay = () => {
    return this.state.dateObject.format("D");
  };
  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject).startOf("month").format("d"); // Day of week 0...1..5...6
    return firstDay;
  };
  month = () => {
    return this.props.t(this.state.dateObject.format("MMMM"));
  };
  showMonth = (e, month) => {
    this.setState({
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: true,
    });
  };
  setMonth = (month) => {
    let monthNo = this.state.allmonths.indexOf(month);
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("month", monthNo);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: true,
    });
    this.props.onChangeCalendar([
      monthNo + 1,
      this.state.dateObject.format("Y"),
    ]);
  };
  MonthList = (props) => {
    let months = [];
    props.data.map((data) => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={(e) => {
            this.setMonth(data);
          }}
        >
          <span>{this.props.t(data)}</span>
        </td>
      );
      return false;
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i === 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let monthlist = rows.map((d, i) => {
      return <tr key={d + Math.random()}>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Month</th>
          </tr>
        </thead>
        <tbody>{monthlist}</tbody>
      </table>
    );
  };
  IsHolidayCard = () => (
    <ul className="list-group text-left">
      <li className="list-group-item" style={{ position: "relative" }}>
        {this.props.t("Status Masuk")} :
        <span
          style={{
            fontWeight: "bold",
            color: "#656565",
          }}
        >
          &nbsp;{this.props.t("Libur")}
        </span>
      </li>
      <li className="list-group-item">
        {this.props.t("Status Pulang")} :
        <span
          style={{
            fontWeight: "bold",
            color: "#656565",
          }}
        >
          &nbsp;{this.props.t("Libur")}
        </span>
      </li>
    </ul>
  );
  showYearEditor = () => {
    this.setState({
      showYearNav: true,
      showCalendarTable: true,
    });
  };
  onCloseYearEditor = () => {
    this.setState({
      showYearNav: false,
      showCalendarTable: true,
    });
  };
  onPrev = () => {
    let curr = "";
    if (this.state.showMonthTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }

    this.setState({
      dateObject: this.state.dateObject.subtract(1, curr),
    });
    this.props.onChangeCalendar([
      this.state.dateObject.format("M"),
      this.state.dateObject.format("Y"),
    ]);
  };
  onNext = () => {
    let curr = "";
    if (this.state.showMonthTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.add(1, curr),
    });
    this.props.onChangeCalendar([
      this.state.dateObject.format("M"),
      this.state.dateObject.format("Y"),
    ]);
  };
  setYear = (year) => {
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("year", year);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showYearNav: !this.state.showYearNav,
    });
    this.props.onChangeCalendar([this.state.dateObject.format("M"), year]);
  };
  onYearChange = (e) => {
    this.setYear(e.target.value);
  };
  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment().subtract(7, "year");
    var stopDates = moment(stopDate);
    while (currentDate <= stopDates) {
      dateArray.push(moment(currentDate).format("YYYY"));
      currentDate = moment(currentDate).add(1, "year");
    }

    return dateArray;
  }
  YearTable = (props) => {
    let months = [];
    let nextten = moment().set("year", props).add(4, "year");
    let tenyear = this.getDates(props, nextten);
    tenyear.map((data) => {
      months.push(
        <td
          key={data}
          className={
            "list-year " + data === moment().year() ? "current-year" : null
          }
          onClick={(e) => {
            this.setYear(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
      return false;
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i === 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let yearlist = rows.map((d, i) => {
      return <tr key={i}>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th
              onClick={(e) => {
                this.onCloseYearEditor();
              }}
              colSpan="4"
            >
              Select a Year
            </th>
          </tr>
        </thead>
        <tbody>{yearlist}</tbody>
      </table>
    );
  };
  onDayClick = (e, d) => {
    this.setState({
      showStatusPresensi: true,
      selectedDay: d,
    });
    this.props.onChangeCalendar([
      this.state.dateObject.format("M"),
      this.state.dateObject.format("Y"),
      d,
    ]);
    // ,
    //   () => {
    //     console.log("SELECTED DAY: ", this.state.selectedDay);
    //   }
  };
  render() {
    let presensiMapel = this.props.subjectAttendance;
    // console.log("presensiMapel", presensiMapel);
    let weekdayshortname = this.weekdayshort.map((day) => {
      return <th key={day}>{this.props.t(day)}</th>;
    });
    let blanks = [];
    let counter = 0;
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(
        <td key={counter + Math.random()} className="calendar-day empty">
          {""}
        </td>
      );
      counter++;
    }
    let daysInMonth = [];
    let agenda = this.props.presensi;
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let currentDay =
        parseInt(d) === parseInt(moment().format("D")) &&
        parseInt(this.state.dateObject.format("M")) ===
          parseInt(new Date().getMonth() + 1) &&
        parseInt(this.state.dateObject.format("Y")) ===
          parseInt(new Date().getFullYear())
          ? "today"
          : "";
      let notPresensi = agenda.find(
        (e) =>
          parseInt(e.date) === parseInt(d) &&
          e.checkInStatus === "Belum Presensi" &&
          parseInt(e.month) === parseInt(this.state.dateObject.format("M")) &&
          parseInt(e.year) === parseInt(this.state.dateObject.format("Y"))
      )
        ? d === this.props.date
          ? "not-presensi-active"
          : "not-presensi"
        : "";
      let late = agenda.find(
        (e) =>
          parseInt(e.date) === parseInt(d) &&
          e.checkInStatus === "Terlambat" &&
          parseInt(e.month) === parseInt(this.state.dateObject.format("M")) &&
          parseInt(e.year) === parseInt(this.state.dateObject.format("Y"))
      )
        ? parseInt(d) === parseInt(this.props.date)
          ? "late-active"
          : "late"
        : "";
      let presensi = agenda.find(
        (e) =>
          parseInt(e.date) === parseInt(d) &&
          e.checkInStatus === "Masuk" &&
          parseInt(e.month) === parseInt(this.state.dateObject.format("M")) &&
          parseInt(e.year) === parseInt(this.state.dateObject.format("Y"))
      )
        ? d === this.props.date
          ? "presensi-active"
          : "presensi"
        : "";
      let sick = agenda.find(
        (e) =>
          parseInt(e.date) === parseInt(d) &&
          e.checkInStatus === "Sakit" &&
          parseInt(e.month) === parseInt(this.state.dateObject.format("M")) &&
          parseInt(e.year) === parseInt(this.state.dateObject.format("Y"))
      )
        ? d === this.props.date
          ? "sick-active"
          : "sick"
        : "";

      let permission = agenda.find(
        (e) =>
          parseInt(e.date) === parseInt(d) &&
          e.checkInStatus === "Izin" &&
          parseInt(e.month) === parseInt(this.state.dateObject.format("M")) &&
          parseInt(e.year) === parseInt(this.state.dateObject.format("Y"))
      )
        ? d === this.props.date
          ? "permission-active"
          : "permission"
        : "";

      let alpha = agenda.find(
        (e) =>
          parseInt(e.date) === parseInt(d) &&
          e.checkInStatus === "Tanpa Keterangan" &&
          parseInt(e.month) === parseInt(this.state.dateObject.format("M")) &&
          parseInt(e.year) === parseInt(this.state.dateObject.format("Y"))
      )
        ? d === this.props.date
          ? "alpha-active"
          : "alpha"
        : "";
      let isHoliday = agenda.find(
        (e) =>
          e.date == d &&
          e.isActiveDay == false &&
          e.month == this.state.dateObject.format("M") &&
          e.year == this.state.dateObject.format("Y")
      )
        ? d == this.props.date
          ? "holiday-active"
          : "holiday"
        : "";
      if (isHoliday) {
        daysInMonth.push(
          <td
            key={d}
            onClick={(e) => {
              this.onDayClick(e, d);
            }}
            className={`calendar-day ${isHoliday}`}
          >
            <span>{d}</span>
          </td>
        );
      } else {
        daysInMonth.push(
          <td
            key={d}
            onClick={(e) => {
              this.onDayClick(e, d);
            }}
            className={`calendar-day ${currentDay} ${late} ${presensi} ${notPresensi} ${sick} ${permission} ${alpha}`}
          >
            <span>{d}</span>
          </td>
        );
      }
    }
    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr key={i}>{d}</tr>;
    });
    return (
      <div>
        <div className="tail-datetime-calendar">
          <div className="calendar-navi">
            <span
              onClick={(e) => {
                this.onPrev();
              }}
              className="calendar-button button-prev"
            />
            {!this.state.showMonthTable && !this.state.showYearEditor && (
              <span
                onClick={(e) => {
                  this.showMonth();
                }}
                className="calendar-label"
              >
                {this.month()}
              </span>
            )}
            <span
              className="calendar-label"
              onClick={(e) => {
                this.showYearEditor();
              }}
            >
              {this.year()}
            </span>

            <span
              onClick={(e) => {
                this.onNext();
              }}
              className="calendar-button button-next"
            />
          </div>
          <div className="calendar-date">
            {this.state.showYearNav && <this.YearTable props={this.year()} />}
            {this.state.showMonthTable && (
              <this.MonthList data={moment.months()} />
            )}
          </div>

          {this.state.showCalendarTable && (
            <div className="calendar-date">
              <table className="calendar-day">
                <thead>
                  <tr>{weekdayshortname}</tr>
                </thead>
                <tbody>{daysinmonth}</tbody>
              </table>
            </div>
          )}
        </div>
        <br />
        {this.state.showStatusPresensi ? (
          agenda.filter(
            (e) =>
              parseInt(e.month) ===
                parseInt(this.state.dateObject.format("M")) &&
              parseInt(e.date) === parseInt(this.state.selectedDay)
          ).length ? (
            agenda.map(
              (e) =>
                parseInt(e.month) ===
                  parseInt(this.state.dateObject.format("M")) &&
                parseInt(e.date) === parseInt(this.state.selectedDay) && (
                  <div
                    style={{ marginBottom: "2em" }}
                    className="card"
                    key={e._id}
                  >
                    <div className="card-body" style={{ padding: 0 }}>
                      <div className="schedule-name">
                        {e.scheduleName
                          ? e.scheduleName
                          : this.props.t("Presensi")}
                      </div>
                      {e.isActiveDay === false ? (
                        <this.IsHolidayCard />
                      ) : (
                        <ul className="list-group text-left">
                          <li
                            className="list-group-item"
                            style={{ position: "relative" }}
                          >
                            Status Masuk:
                            <span
                              style={{
                                fontWeight: "bold",
                                color:
                                  e.checkInStatus === "Masuk"
                                    ? "#badc58"
                                    : e.checkInStatus === "Belum Presensi"
                                    ? "#fc4f4f"
                                    : e.checkInStatus === "Terlambat"
                                    ? "#fea748"
                                    : "inherit",
                              }}
                            >
                              &nbsp;{e.checkInStatus}
                            </span>
                            <span
                              style={{
                                float: "right",
                                color: "#00a8ff",
                                fontWeight: "bold",
                              }}
                            >
                              {e.checkInMode === "Mesin"
                                ? `${Math.floor(e.checkInMinutes / 60)}:${
                                    e.checkInMinutes -
                                      Math.floor(e.checkInMinutes / 60) * 60 <
                                    10
                                      ? "0" +
                                        (e.checkInMinutes -
                                          Math.floor(e.checkInMinutes / 60) *
                                            60)
                                      : e.checkInMinutes -
                                        Math.floor(e.checkInMinutes / 60) * 60
                                  }`
                                : null}
                            </span>
                          </li>
                          <li className="list-group-item">
                            Status Pulang:
                            <span
                              style={{
                                fontWeight: "bold",
                                color:
                                  e.checkOutStatus === "Pulang"
                                    ? "#badc58"
                                    : e.checkOutStatus === "Belum Presensi"
                                    ? "#fc4f4f"
                                    : e.checkOutStatus === "Pulang Dulu"
                                    ? "#fea748"
                                    : "inherit",
                              }}
                            >
                              &nbsp;{e.checkOutStatus}
                            </span>
                            <span
                              style={{
                                float: "right",
                                color: "#00a8ff",
                                fontWeight: "bold",
                              }}
                            >
                              {e.checkOutMode === "Mesin"
                                ? `${Math.floor(e.checkOutMinutes / 60)}:${
                                    e.checkOutMinutes -
                                      Math.floor(e.checkOutMinutes / 60) * 60 <
                                    10
                                      ? "0" +
                                        (e.checkOutMinutes -
                                          Math.floor(e.checkOutMinutes / 60) *
                                            60)
                                      : e.checkOutMinutes -
                                        Math.floor(e.checkOutMinutes / 60) * 60
                                  }`
                                : null}
                            </span>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                )
            )
          ) : (
            <div />
          )
        ) : (
          <div />
        )}

        <div className="text-center" style={{ marginBottom: "5em" }}>
          {presensiMapel && presensiMapel.length !== 0 ? (
            <div className="card">
              <div className="card-body" style={{ padding: 0 }}>
                <div className="schedule-name">
                  {this.props.t("Presensi Mata Pelajaran")}
                </div>
                <ul className="list-group text-left">
                  {presensiMapel.map((p) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{ position: "relative", paddingTop: 0 }}
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "250px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {p.Subject ? p.Subject.name : "-"}
                        <br />
                        <span className="small">
                          {p.status !== "Masuk"
                            ? `${this.props.t("Ket")}: ${
                                p.description ? p.description : "-"
                              }`
                            : null}
                        </span>
                      </div>
                      <span
                        className="text-right float-right"
                        style={{
                          fontWeight: "bold",
                          color:
                            p.status === "Masuk"
                              ? "#badc58"
                              : p.status === "Izin"
                              ? "#fc4f4f"
                              : p.status === "Terlambat"
                              ? "#fea748"
                              : p.status === "Tanpa Keterangan"
                              ? "#8e44ad"
                              : "inherit",
                        }}
                      >
                        {p.status ? this.props.t(p.status) : "-"}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
