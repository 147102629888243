import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../../App";
import { handleError } from "../../libs/errors";
import dayjs from "dayjs";

let urlValues;

class KegiatanRumah extends Component {
  state = {
    clickedGroup: [],
    aspectActivityGroup: [],
    aspectActivityScoreDescriptionPerDate: "",
  };

  componentDidMount = () => {
    this.componentWillReceiveProps(this.props);
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allAspectActivityGroups) {
      this.setState({
        // aspectActivityGroup: nextProps.allAspectActivityGroups,
        aspectActivityGroup: nextProps.allAspectActivityGroups.map((g) => {
          let description = "";
          let videoUrl = "";
          let photoUrl = "";
          if (
            g.littleCamelDescriptionObject &&
            g.littleCamelDescriptionObject.description
          ) {
            description = g.littleCamelDescriptionObject.description;
          }

          if (
            g.littleCamelDescriptionObject &&
            g.littleCamelDescriptionObject.videoUrl
          ) {
            videoUrl = g.littleCamelDescriptionObject.videoUrl;
          }

          if (
            g.littleCamelDescriptionObject &&
            g.littleCamelDescriptionObject.photoUrl
          ) {
            photoUrl = g.littleCamelDescriptionObject.photoUrl;
          }
          return {
            ...g,
            description,
            photoUrl,
            videoUrl,
          };
        }),
        aspectActivityScoreDescriptionPerDate:
          nextProps.aspectActivityScoreDescriptionPerDate,
      });
    }
  };

  handleShowAspekDetail = (group) => (e) => {
    const { clickedGroup } = this.state;
    if (clickedGroup.length === 0) {
      this.setState({
        clickedGroup: [group._id],
      });
    } else {
      const idx = clickedGroup.findIndex((g) => g === group._id);

      if (idx < 0) {
        this.setState({
          clickedGroup: [...clickedGroup, group._id],
        });
      } else {
        this.setState({
          clickedGroup: clickedGroup.filter((g) => g !== group._id),
        });
      }
    }
  };

  render() {
    const {
      clickedGroup,
      aspectActivityGroup,
      aspectActivityScoreDescriptionPerDate,
    } = this.state;
    const { date } = this.props;

    return (
      <View
        style={{
          padding: 10,
        }}
      >
        <p
          style={{
            fontSize: 18,
            margin: 0,
          }}
        >
          <b>Kegiatan Siswa</b>
        </p>
        <p
          style={{
            fontSize: 12,
            margiBottom: "10px",
          }}
        >
          {dayjs(date).format("DD MMMM YYYY")}
        </p>

        <View
          style={{
            backgroundColor: "#fff",
          }}
        >
          {aspectActivityGroup.map((group) => {
            const idx = clickedGroup.findIndex((g) => g === group._id);

            return (
              <div>
                <div
                  style={{
                    backgroundColor: "#d1f1f1",
                    padding: "10px",
                  }}
                  onClick={this.handleShowAspekDetail(group)}
                >
                  <b>
                    <i
                      className={`fa ${
                        idx === -1 ? "fa-caret-down" : "fa-caret-up"
                      }`}
                    />{" "}
                    {group.name}
                  </b>
                </div>

                {group.Aspects.map((aspect, index) => {
                  if (aspect.scoringType === "checklist") {
                    return (
                      <div className={idx === -1 ? "d-none" : "d-block"}>
                        <div
                          className={"row"}
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <div
                            className="col-11 d-flex align-items-center"
                            style={{
                              paddingTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            ({aspect.code}) {aspect.name}
                          </div>
                          <div
                            className="col-1 d-flex align-items-center"
                            style={{
                              padding: "0px",
                            }}
                          >
                            <input
                              className="form-control"
                              type="checkbox"
                              style={{
                                height: "20px",
                              }}
                              value={aspect.AspectActivityScore}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className={idx === -1 ? "d-none" : "d-block"}>
                        <div
                          className={"row"}
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <div
                            className="col-9 d-flex align-items-center"
                            style={{
                              paddingTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            ({aspect.code}) {aspect.name}
                          </div>
                          <div
                            className="col-3 d-flex align-items-center"
                            style={{
                              paddingTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <select
                              className="form-control"
                              value={aspect.AspectActivityCustomScore.score}
                              disabled
                            >
                              <option value={0} disabled>
                                Nilai
                              </option>
                              {aspect.scoringTypeCustom.map((sc) => (
                                <option value={sc.score}>{sc.score}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                {idx !== -1 ? (
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "5px",
                        marginTop: "10px",
                      }}
                    >
                      Catatan Sekolah
                    </p>
                    <textarea
                      style={{
                        marginTop: 10,
                      }}
                      className="form-control"
                      placeholder="Catatan Sekolah"
                      value={group.description}
                      disabled
                    />
                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                      }}
                    >
                      Lampiran Gambar (Max 50 MB)
                    </p>
                    {group.photoUrl ? (
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={group.photoUrl}
                      />
                    ) : null}

                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                      }}
                    >
                      Lampiran Video (Max 50 MB)
                    </p>

                    {group.videoUrl ? (
                      <video style={{ width: "100%", height: "auto" }} controls>
                        <source src={group.videoUrl} type="video/mp4" />
                      </video>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          })}
        </View>
      </View>
    );
  }
}

const ASPECT_GROUP_QUERY = gql`
  query listQuery(
    $academicYear: String!
    $studentId: String!
    $date: String!
    $category: String!
    $type: String!
  ) {
    allAspectActivityGroups(
      academicYear: $academicYear
      studentId: $studentId
      category: $category
      date: $date
    ) {
      _id
      name
      Aspects {
        _id
        code
        name
        aspectGroupId
        AspectActivityScore(studentId: $studentId, date: $date)
        AspectActivityCustomScore(studentId: $studentId, date: $date)

        scoringType
        scoringTypeCustom
      }
      littleCamelDescriptionObject(studentId: $studentId, type: $type)
    }

    aspectActivityScoreDescriptionPerDate(
      studentId: $studentId
      date: $date
      category: $category
    )
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
    // uri: "http://localhost:9001/graphql",
});

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  // console.log(urlValues);
  return (
    <ApolloConsumer>
      {(client) => (
        <Query
          query={ASPECT_GROUP_QUERY}
          client={mwvClient}
          variables={{
            _id: urlValues.sessionId,
            ...props,
            type: "CATATAN SEKOLAH",
          }}
        >
          {({ error, loading, data, refetch }) => (
            <div>
              <KegiatanRumah
                {...props}
                client={client}
                error={error}
                loading={loading}
                allAspectActivityGroups={
                  data && data.allAspectActivityGroups
                    ? data.allAspectActivityGroups
                    : []
                }
                aspectActivityScoreDescriptionPerDate={
                  data ? data.aspectActivityScoreDescriptionPerDate : ""
                }
                refetch={refetch}
              />
            </div>
          )}
        </Query>
      )}
    </ApolloConsumer>
  );
});
