import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../../App";
import { handleError } from "../../libs/errors";
import dayjs from "dayjs";

let urlValues;

class KegiatanRumah extends Component {
  state = {
    clickedGroup: [],
    aspectActivityGroup: [],
    aspectActivityScoreDescriptionPerDate: "",
  };

  componentDidMount = () => {
    this.componentWillReceiveProps(this.props);
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allAspectActivityGroups) {
      this.setState({
        // aspectActivityGroup: nextProps.allAspectActivityGroups,
        aspectActivityGroup: nextProps.allAspectActivityGroups.map((g) => {
          let description = "";
          let videoUrl = "";
          let photoUrl = "";
          if (
            g.littleCamelDescriptionObject &&
            g.littleCamelDescriptionObject.description
          ) {
            description = g.littleCamelDescriptionObject.description;
          }

          if (
            g.littleCamelDescriptionObject &&
            g.littleCamelDescriptionObject.videoUrl
          ) {
            videoUrl = g.littleCamelDescriptionObject.videoUrl;
          }

          if (
            g.littleCamelDescriptionObject &&
            g.littleCamelDescriptionObject.photoUrl
          ) {
            photoUrl = g.littleCamelDescriptionObject.photoUrl;
          }
          return {
            ...g,
            description,
            photoUrl,
            videoUrl,
          };
        }),
        aspectActivityScoreDescriptionPerDate:
          nextProps.aspectActivityScoreDescriptionPerDate,
      });
    }
  };

  handleShowAspekDetail = (group) => (e) => {
    const { clickedGroup } = this.state;
    if (clickedGroup.length === 0) {
      this.setState({
        clickedGroup: [group._id],
      });
    } else {
      const idx = clickedGroup.findIndex((g) => g === group._id);

      if (idx < 0) {
        this.setState({
          clickedGroup: [...clickedGroup, group._id],
        });
      } else {
        this.setState({
          clickedGroup: clickedGroup.filter((g) => g !== group._id),
        });
      }
    }
  };

  handleCheckApsect = (aspek) => async (e) => {
    showLoadingSpinner();
    try {
      const { date, studentId } = this.props;
      urlValues = queryString.parse(this.props.location.search);
      // console.log({
      //   date,
      //   studentId,
      //   aspectActivityId: aspek._id,
      //   isChecked: e.target.checked,
      // });
      await mwvClient.mutate({
        mutation: SAVE_SCORE,
        variables: {
          sessionId: urlValues.sessionId,
          date,
          studentId,
          aspectActivityId: aspek._id,
          isChecked: e.target.checked,
        },
      });

      await this.props.refetch();

      addNotification({
        level: "success",
        message: "Data berhasil disimpan",
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  handleInputScore = (aspect) => async (e) => {
    showLoadingSpinner();
    try {
      const { date, studentId, router } = this.props;
      urlValues = queryString.parse(this.props.location.search);
      await mwvClient.mutate({
        mutation: SAVE_SCORE,
        variables: {
          sessionId: urlValues.sessionId,
          date,
          studentId,
          aspectActivityId: aspect._id,
          isChecked: aspect.AspectActivityScore,
          score: parseInt(e.target.value),
          descritiveScore: "",
        },
      });

      await this.props.refetch();
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  handleDescription = (group, key) => (e) => {
    if (e) e.preventDefault();
    this.setState({
      aspectActivityGroup: this.state.aspectActivityGroup.map((gr) =>
        gr._id !== group._id
          ? gr
          : {
              ...group,
              [key]: e.target.value,
            }
      ),
    });
  };

  handleVideoInput = (group, key) => async (e) => {
    if (e) e.preventDefault();
    const { studentId, date, category } = this.props;
    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * 50) {
      return handleError({
        message: "Maximum allowed video size is 50MB!",
      });
    }
    let reader = new FileReader();
    reader.onloadend = async () => {
      this.setState({
        aspectActivityGroup: this.state.aspectActivityGroup.map((gr) =>
          gr._id !== group._id
            ? gr
            : {
                ...group,
                ["videoUrl"]: reader.result,
              }
        ),
      });
      console.log("Save");
      await mwvClient.mutate({
        mutation: SAVE_DESCRIPTION,
        variables: {
          aspectGroupId: group._id,
          studentId,
          date,
          description: group.description,
          type: "CATATAN ORANG TUA",
          category,
          photoUrl: group.photoUrl,
          videoUrl: reader.result,
        },
      });
      console.log("done save!");
    };
    reader.readAsDataURL(file);

    showLoadingSpinner();
    try {
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
  handleImageInput = (group, key) => async (e) => {
    if (e) e.preventDefault();
    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * 50) {
      return handleError({
        message: "Maximum allowed image size is 50MB!",
      });
    }
    let reader = new FileReader();
    reader.onloadend = async () => {
      this.setState({
        aspectActivityGroup: this.state.aspectActivityGroup.map((gr) =>
          gr._id !== group._id
            ? gr
            : {
                ...group,
                [key]: reader.result,
              }
        ),
      });
    };
    reader.readAsDataURL(file);
    showLoadingSpinner();
    try {
      const { date, studentId } = this.props;
      await mwvClient.mutate({
        mutation: SAVE_DESCRIPTION,
        variables: {
          aspectGroupId: group._id,
          studentId,
          date,
          description: group.description,
          type: "CATATAN ORANG TUA",
          category: "ORTU",
          photoUrl: reader.result,
          videoUrl: group.videoUrl,
        },
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  handleSelectAll = (type, gr) => async (e) => {
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);

      const { aspectActivityGroup } = this.state;
      const { date, studentId, category } = this.props;
      for (const group of aspectActivityGroup.filter((g) => g._id === gr._id)) {
        for (const aspek of group.Aspects) {
          await mwvClient.mutate({
            mutation: SAVE_SCORE,
            variables: {
              sessionId: urlValues.sessionId,
              date,
              studentId,
              aspectActivityId: aspek._id,
              isChecked: type === "select" ? true : false,
            },
          });
        }
      }
      await this.props.refetch();
      addNotification({
        level: "success",
        message: "Data berhasil disimpan",
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
  render() {
    const {
      clickedGroup,
      aspectActivityGroup,
      aspectActivityScoreDescriptionPerDate,
    } = this.state;
    const { date } = this.props;

    return (
      <View
        style={{
          padding: 10,
        }}
      >
        <p
          style={{
            fontSize: 18,
            margin: 0,
          }}
        >
          <b>Kegiatan Siswa</b>
        </p>
        <p
          style={{
            fontSize: 12,
            margiBottom: "10px",
          }}
        >
          {dayjs(date).format("DD MMMM YYYY")}
        </p>

        <View
          style={{
            backgroundColor: "#fff",
          }}
        >
          {aspectActivityGroup.map((group) => {
            const idx = clickedGroup.findIndex((g) => g === group._id);

            return (
              <div>
                <div
                  style={{
                    backgroundColor: "#d1f1f1",
                    padding: "10px",
                  }}
                  onClick={this.handleShowAspekDetail(group)}
                >
                  <b>
                    <i
                      className={`fa ${
                        idx === -1 ? "fa-caret-down" : "fa-caret-up"
                      }`}
                    />{" "}
                    {group.name}
                  </b>
                </div>

                {group.Aspects.map((aspect, index) => {
                  if (aspect.scoringType === "checklist") {
                    return (
                      <div className={idx === -1 ? "d-none" : "d-block"}>
                        {index === 0 ? (
                          <div
                            className="d-flex flex-row-reverse"
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <button
                              className="btn btn-sm btn-rounded"
                              style={{
                                backgroundColor: "#fff",
                                borderColor: "#4FC3F7",
                                borderRadius: "15px",
                                color: "#4FC3F7",
                              }}
                              onClick={this.handleSelectAll("select", group)}
                            >
                              <b>
                                <i className="fa fa-check" /> Semua
                              </b>
                            </button>
                            <button
                              className="btn btn-sm btn-rounded"
                              style={{
                                backgroundColor: "#fff",
                                borderColor: "#F44336",
                                borderRadius: "15px",
                                color: "#F44336",
                                marginRight: "10px",
                              }}
                              onClick={this.handleSelectAll("reset", group)}
                            >
                              <b>
                                <i className="fa fa-ban" /> Reset
                              </b>
                            </button>
                          </div>
                        ) : (
                          <div />
                        )}
                        <div
                          className={"row"}
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <div
                            className="col-11 d-flex align-items-center"
                            style={{
                              paddingTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            ({aspect.code}) {aspect.name}
                          </div>
                          <div
                            className="col-1 d-flex align-items-center"
                            style={{
                              padding: "0px",
                            }}
                          >
                            <input
                              className="form-control"
                              type="checkbox"
                              style={{
                                height: "20px",
                              }}
                              value={aspect.AspectActivityScore}
                              onChange={this.handleCheckApsect(aspect)}
                              checked={aspect.AspectActivityScore}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className={idx === -1 ? "d-none" : "d-block"}>
                        <div
                          className={"row"}
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <div
                            className="col-9 d-flex align-items-center"
                            style={{
                              paddingTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            ({aspect.code}) {aspect.name}
                          </div>
                          <div
                            className="col-3 d-flex align-items-center"
                            style={{
                              paddingTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <select
                              className="form-control"
                              value={aspect.AspectActivityCustomScore.score}
                              onChange={this.handleInputScore(aspect)}
                            >
                              <option value={0} disabled>
                                Nilai
                              </option>
                              {aspect.scoringTypeCustom.map((sc) => (
                                <option value={sc.score}>{sc.score}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                {idx !== -1 ? (
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "5px",
                        marginTop: "10px",
                      }}
                    >
                      Catatan Orang Tua
                    </p>
                    <textarea
                      style={{
                        marginTop: 10,
                      }}
                      className="form-control"
                      placeholder="Catatan Oleh Orang Tua"
                      value={group.description}
                      onChange={this.handleDescription(group, "description")}
                      onBlur={this.saveDescription(group, "CATATAN ORANG TUA")}
                    />
                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                      }}
                    >
                      Lampiran Gambar (Max 50 MB)
                      <input
                        type="file"
                        accept="image/*"
                        onChange={this.handleImageInput(group, "photoUrl")}
                      />
                    </p>
                    {group.photoUrl ? (
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={group.photoUrl}
                      />
                    ) : null}

                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                      }}
                    >
                      Lampiran Video (Max 50 MB)
                      <input
                        type="file"
                        accept="video/*"
                        onChange={this.handleVideoInput(group, "videoUrl")}
                      />
                    </p>

                    {group.videoUrl ? (
                      <video style={{ width: "100%", height: "auto" }} controls>
                        <source src={group.videoUrl} type="video/mp4" />
                      </video>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          })}
        </View>
      </View>
    );
  }

  saveDescription = (group, type) => async (e) => {
    showLoadingSpinner();
    try {
      const { studentId, date, category } = this.props;
      const { aspectActivityScoreDescriptionPerDate } = this.state;
      await mwvClient.mutate({
        mutation: SAVE_DESCRIPTION,
        variables: {
          aspectGroupId: group._id,
          studentId,
          date,
          description: group.description,
          type,
          category,
          photoUrl: group.photoUrl,
          videoUrl: group.videoUrl,
        },
      });

      await this.props.refetch();
      addNotification({
        level: "success",
        message: "Data berhasil disimpan",
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
}

const ASPECT_GROUP_QUERY = gql`
  query listQuery(
    $academicYear: String!
    $studentId: String!
    $date: String!
    $category: String!
    $type: String!
  ) {
    allAspectActivityGroups(
      academicYear: $academicYear
      studentId: $studentId
      category: $category
      date: $date
    ) {
      _id
      name
      Aspects {
        _id
        code
        name
        aspectGroupId
        AspectActivityScore(studentId: $studentId, date: $date)
        AspectActivityCustomScore(studentId: $studentId, date: $date)

        scoringType
        scoringTypeCustom
      }
      littleCamelDescriptionObject(studentId: $studentId, type: $type)
    }

    aspectActivityScoreDescriptionPerDate(
      studentId: $studentId
      date: $date
      category: $category
    )
  }
`;

const SAVE_SCORE = gql`
  mutation saveActivityScore(
    $sessionId: String!
    $date: String!
    $studentId: String!
    $aspectActivityId: String!
    $isChecked: Boolean!
    $score: Int
    $descritiveScore: String
  ) {
    saveActivityScore(
      sessionId: $sessionId
      date: $date
      studentId: $studentId
      aspectActivityId: $aspectActivityId
      isChecked: $isChecked
      score: $score
      descritiveScore: $descritiveScore
    )
  }
`;

const SAVE_DESCRIPTION = gql`
  mutation saveActivityDescriptionPerGroup(
    $aspectGroupId: String!
    $studentId: String!
    $date: String!
    $description: String
    $type: String
    $category: String
    $photoUrl: String
    $videoUrl: String
  ) {
    saveActivityDescriptionPerGroup(
      aspectGroupId: $aspectGroupId
      studentId: $studentId
      date: $date
      description: $description
      type: $type
      category: $category
      photoUrl: $photoUrl
      videoUrl: $videoUrl
    )
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  // console.log(urlValues);
  return (
    <ApolloConsumer>
      {(client) => (
        <Mutation mutation={SAVE_SCORE}>
          {(saveActivityScore) => (
            <Mutation mutation={SAVE_DESCRIPTION}>
              {(saveActivityDescription) => (
                <Query
                  query={ASPECT_GROUP_QUERY}
                  client={mwvClient}
                  variables={{
                    _id: urlValues.sessionId,
                    ...props,
                    type: "CATATAN ORANG TUA",
                  }}
                >
                  {({ error, loading, data, refetch }) => (
                    <div>
                      <KegiatanRumah
                        {...props}
                        client={client}
                        error={error}
                        loading={loading}
                        allAspectActivityGroups={
                          data && data.allAspectActivityGroups
                            ? data.allAspectActivityGroups
                            : []
                        }
                        aspectActivityScoreDescriptionPerDate={
                          data ? data.aspectActivityScoreDescriptionPerDate : ""
                        }
                        refetch={refetch}
                        saveActivityDescription={saveActivityDescription}
                        saveActivityScore={saveActivityScore}
                      />
                    </div>
                  )}
                </Query>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </ApolloConsumer>
  );
});
